<template>
    <v-app>
        <Header />
        <v-container>

            <v-row
                align="center"
                justify="space-around"
            >
                <v-btn 
                    text
                    @click="showList(), classes()"
                    color="#467599"
                    :class="{active: isActive}"
                >
                    {{ $t('listOfCamera') }}
                </v-btn>
                <v-btn
                    text
                    color="#467599"
                    @click="showUser(), classes1()"
                     :class="{active1: isActive1}"
                >
                    {{ $t('listOfUser') }}
                </v-btn>
                <v-btn
                    text
                    color="#467599"
                    @click="addUserObj(), classes2()"
                    :class="{active2: isActive2}"
                >
                    {{ $t('addNewUser') }}
                </v-btn>
            </v-row>
            <br>
            <hr>
            <div v-if="isList == '1'">
                <br>
                    <h2>{{ $t('object') }} {{ objName }}</h2>
                    <br>
                    <v-card 
                        class="ml-0 listCamera"
                        width="100%"
                    >
                        <div v-for="item in objects" :key="item.id">
                            <v-list two-line>
                                <v-list-item>
                                    <v-list-item-content>
                                        <h3>{{ $t('name') }}: {{ item.camera_name }} {{ item.id }}</h3> <br>
                                        <v-list-item-title >IP_link: {{ item.ip_link }}</v-list-item-title> <br>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-row>
                                            <v-col cols="4">
                                                <div class="text-center">
                                                    <v-dialog
                                                        v-model="dialog5"
                                                        width="500"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                class="mx-2"
                                                                fab
                                                                dark
                                                                small
                                                                color="primary"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="getId(item.id)"
                                                            >
                                                                <v-icon 
                                                                    dark
                                                                >
                                                                    mdi-account-plus
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                            
                                                            <v-card>
                                                              
                                                                <v-card-title>
                                                                    <span class="headline">{{  $t('setUser') }}</span>
                                                                </v-card-title>
                                                                <v-card-text>
                                                                    <v-container>
                                                                        <v-row>
                                                                            <v-col cols="12">
                                                                                <v-select                          
                                                                                    :label="$t('select')"
                                                                                    required
                                                                                    :items="usersList"
                                                                                    item-text="login"
                                                                                    item-value="id"
                                                                                    v-model="userId"
                                                                                ></v-select>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    color="blue darken-1"
                                                                    text
                                                                    @click="dialog5 = false"
                                                                >
                                                                    {{ $t('close') }}
                                                                </v-btn>
                                                                <template>
                                                                    <v-row justify="center">
                                                                        <v-dialog
                                                                        v-model="dialog7"
                                                                        persistent
                                                                        max-width="290"
                                                                        >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                color="blue darken-1"
                                                                                text
                                                                                @click="setUser()"
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                            >
                                                                                {{ $t('save') }}
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card v-if="status">
                                                                            <v-card-title class="text-h5">
                                                                            </v-card-title>
                                                                            <v-card-text><v-icon color="green darken-1">mdi-check-outline</v-icon>  {{ $t('success') }}</v-card-text>
                                                                            <v-card-actions>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn
                                                                                color="green darken-1"
                                                                                text
                                                                                @click="reloadWindow(), dialog7 = false, dialog5 = false"
                                                                            >
                                                                                OK
                                                                            </v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                        <v-card v-if="!status">
                                                                            <v-card-title class="text-h5">
                                                                            </v-card-title>
                                                                            <v-card-text><v-icon color="#D64045">mdi-alert-circle</v-icon>  Please select one user</v-card-text>
                                                                            <v-card-actions>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn
                                                                                color="#D64045"
                                                                                text
                                                                                @click="dialog7 = false"
                                                                            >
                                                                                OK
                                                                            </v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                        </v-dialog>
                                                                    </v-row>
                                                                    </template>
                                                                
                                                                </v-card-actions>
                                                            </v-card>
                                                            
                                                    </v-dialog>
                                                </div>
                                                
                                            </v-col>           
                                            <v-col cols="4">
                                                <div class="text-center">    
                                                       <v-btn
                                                            :href="item.camera_link"
                                                            target="_blank"
                                                            class="mx-2"
                                                            fab
                                                            dark
                                                            small
                                                            color="primary"
                                                        >
                                                            <v-icon dark>
                                                                mdi-video
                                                            </v-icon>
                                                        </v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="4">
                                                <div class="text-center">
                                                    <v-dialog
                                                        v-model="dialog1"
                                                        width="500"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                class="mx-2"
                                                                fab
                                                                dark
                                                                small
                                                                color="primary"
                                                                @click="listOfUsers(item.id)"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-icon 
                                                                    dark
                                                                >
                                                                    mdi-account-details
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="headline grey lighten-2">
                                                                <div class="close" @click="dialog1 = false">
                                                                    <v-icon style="float: right;">mdi-minus-circle</v-icon>
                                                                </div> 
                                                            </v-card-title>
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                    <th class="text-left">
                                                                        ID
                                                                    </th>
                                                                    <th class="text-left">
                                                                        {{ $t('name') }}
                                                                    </th>
                                                                    <th class="text-left">
                                                                        {{ $t('surname') }}
                                                                    </th>
                                                                    <th class="text-left">
                                                                        {{ $t('userID') }}
                                                                    </th>
                                                                    <th class="text-left">
                                                                        {{ $t('deleteUser') }}
                                                                    </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                    v-for="item in accessUser"
                                                                    :key="item.name"
                                                                    >
                                                                    <td>{{ item.id }}</td>
                                                                    <td>{{ item.name }}</td>
                                                                    <td>{{ item.surname }}</td>
                                                                    <td>{{ item.user_id }}</td>
                                                                    <td>
                                                                        <v-btn
                                                                            class="mx-2"
                                                                            text
                                                                            color="#D64045"
                                                                            @click="dialog1 = false, deleteIt(item.id)"
                                                                            >
                                                                            <v-icon dark>
                                                                                mdi-minus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </td>
                                                                    </tr>
                                                                </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card>
                                                    </v-dialog>
                                                </div>
                                                
                                            </v-col>           
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        <v-divider inset></v-divider>
                        </div>
                    </v-card>
            </div>
            <div v-if="isList == '2'" class="shown">
                <br>
                    <br>
                        <v-card
                            class="mx-auto"
                            max-width="400"
                            tile
                            v-for="people in listOfPeople" :key="people.id"
                        >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('fullname') }}: {{ people.name }} {{ people.surname }}</v-list-item-title>
                                <v-list-item-subtitle>
                                {{ $t('email') }}: {{ people.login }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                {{ $t('accessLevel') }}: {{ people.access_level_id }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            
                            </v-list-item>
                        </v-card>
            </div>           

            <div v-if="isList == '3'" class="shown">
                <br>
                    <br>
                        <v-form
                            ref="form"
                            lazy-validation
                            v-model="valid"
                        >
                            <v-text-field
                                v-model="formData.name"
                                :label="$t('name')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.surname"
                                :label="$t('surname')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.lastname"
                                :label="$t('lastname')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.login"
                                :label="$t('email')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.password"
                                :label="$t('password')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>

                            
                            <template  v-if="valid">
                            <v-row justify="center">
                                <v-dialog
                                v-model="dialog6"
                                persistent
                                max-width="290"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                
                                    <v-btn
                                        class="mr-4"
                                        type="submit"
                                        @click.prevent="addNewUser()"
                                        color="#D64045"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="!valid"
                                    >
                                        {{ $t('save') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title></v-card-title>
                                    <v-card-text>{{ $t('success') }}</v-card-text>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="green darken-1"
                                        text
                                        @click="reloadWindow()"
                                    >
                                        OK
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </v-row>
                            </template>
                        </v-form>
            </div>      
            
        </v-container>
        <Footer />
    </v-app>
</template>

<script>
import axios from 'axios'
import Header from './header'
import Footer from './footer'

export default {
    components:{
        Header,
        Footer
    },
    data(){
        return{
            status: false,
            valid: true,
            dialog6: false,
            dialog7: false,
            dialog5: false,
            usersList: [],
            userId: '',
            // dialog4: false,
            isList: '1',
            dialog: false,
            dialog1: false,
            objects: [],
            camera: {},
            listOfPeople: [],
            accessUser: [],
            formData:{
                name: '',
                surname: '',
                lastname: '',
                login: '',
                password: '',
            },
            objName: '',
             isActive: true,
            isActive1: false,
            isActive2: false,
            chosenId: '',
        }
    },
    mounted(){
        var self = this;
        const headers = { "X-Auth": localStorage.getItem("token") };
        axios.get('https://watch.mediana.kz/api/v1/ru/objectAdmin/getObjectCamera', { headers } )
        .then(response =>  {
            console.log(response)
            this.objects = response.data.data;
        })
        .catch(error => console.log(error))
        axios.post('https://watch.mediana.kz/api/v1/ru/objectAdmin/getUserObject', {}, { headers })
        .then(response => { 
            console.log(response)
            this.listOfPeople = response.data.data
           
        })
        .then(error => console.log(error))

         axios.post('https://watch.mediana.kz/api/v1/ru/objectAdmin/getUserObject', {}, { headers })
        .then(response => {
             self.usersList = response.data.data
        }
        )
        .catch(error => console.log(error))

        axios.get('https://watch.mediana.kz/api/v1/ru/objectAdmin/getObjectName', { headers })
        .then(res => self.objName = res.data.data.object_name)
    },
    methods:{
        getId($event){
            console.log($event)
            this.chosenId = $event
        },
        listOfUsers($event){
            var self = this;
            const headers = { "X-Auth": localStorage.getItem("token") };
            axios.post('https://watch.mediana.kz/api/v1/ru/objectAdmin/getCurrentUser', {
               cameraId: $event
            }, { headers })
            .then(response => {
                this.accessUser = response.data.data
            })
            .catch(error => console.log(error));
        },
        deleteIt($event){
            var self = this;
            const headers = { "X-Auth": localStorage.getItem("token") };
            axios.post('https://watch.mediana.kz/api/v1/ru/objectAdmin/deleteUserObject', {
                id: $event,
            }, { headers })
            .then(response => console.log(response))
            .catch(error => console.log(error))
        },
         showList(){
            this.isList = '1';
        },
        showUser(){
            this.isList = '2';
        },
        addUserObj(){
            this.isList = '3';
        },
        addNewUser(){
            this.$refs.form.validate()
            var self = this;
            const headers = { "X-Auth": localStorage.getItem("token") };
            axios.post('https://watch.mediana.kz/api/v1/ru/objectAdmin/addUserObject', self.formData, { headers })
            .then(response => console.log(response))
            .catch(error => console.log(error))
            
        },
        setUser(){
            var self = this;
           const headers = { "X-Auth": localStorage.getItem("token") };
           axios.post('https://watch.mediana.kz/api/v1/ru/objectAdmin/setUserObject', { 
               cameraId: self.chosenId,
               userId: self.userId,
           }, { headers })
           .then(response => {
               console.log(response)  
               if(response.status === 200){
                   this.status = true
               }
            })
           .catch(error => console.log(error));
        },
        classes(){
            this.isActive = true
            this.isActive1 = false
            this.isActive2 = false
        },
        classes1(){
            this.isActive1 = true
            this.isActive = false
            this.isActive2 = false
        },
        classes2(){
            this.isActive2 = true
            this.isActive = false
            this.isActive1 = false
        },
        reloadWindow(){
            window.location.reload();
        }
    }
}
</script>

<style scoped>
.container{
    margin-top: 30px;
}
h2{
    color: #D64045;
}
.v-application .primary {
    background-color: #D64045 !important;
    border-color: #D64045 !important;
}
.listCamera{
    background: #A7BBEC;
}
.shown{
    text-align: center;
}
form{
    background: white;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    z-index: 4;
    box-shadow: 0px 0px 5px #dad5d5;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    z-index: 10;
}
h3{
    font-weight: normal;
    line-height: 45px;
    color: #1D3354;
   
}
.v-list-item__title, a{
      color: #467599;
}
.active{
    background: #467599;
    color: white !important;
}
.active1{
    background: #467599;
    color: white !important;
}
.active2{
    background: #467599;
    color: white !important;
}
</style>