import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    email: localStorage.getItem("email"),
    access: localStorage.getItem("access"),
    isShown: true,
    name: '',
    lang: localStorage.getItem("lang") || 'en',
    objects: localStorage.getItem("objects"),
    status: localStorage.getItem("status"),
  },
  getters:{
    getLanguage(state){
      return state.lang
    }
  },
  mutations: {
    auth(state, response){
      state.token = response.headers.authorization;
      state.email = response.data.data.email;
      state.access = response.data.data.user;
      state.status = response.status
    },
    logout(state){
      state.email = null;
      state.token = null;
      state.access = null;
      state.status = null;
      localStorage.removeItem("email");
      localStorage.removeItem("access");
      localStorage.removeItem("token");
      localStorage.removeItem("status");

    },
    showBtn(state){
      state.showBtnOn = true;
    },
    hideBtn(state){
      state.showBtnOn = false;
    },
    setLang(state, lang) {
			localStorage.setItem('lang', lang);
			state.lang = lang;
		},
    adminPages(state, response){
      state.objects = response.data.data;
      localStorage.setItem("objects", response.data.data)
    },
  },
  actions: {
    async login({ commit }, payload){
      await axios.post('https://watch.mediana.kz/api/v1/ru/auth/login', {
        ...payload,
      })
      .then(response => {
        commit("auth", response);
        localStorage.setItem("token", response.headers.authorization);
        localStorage.setItem("email", response.data.data.email);
        localStorage.setItem("access", response.data.data.user);
        localStorage.setItem("status", response.status);

      })
      .catch(error => {
        console.log(error)
        localStorage.setItem("status", error.status);

      });

    },
    async logout({ commit }){
      await commit("logout");
    },
    adminPage({ commit }){
      var self = this; 
        const headers = { "X-Auth": localStorage.getItem("token") };
        axios.post('https://watch.mediana.kz/api/v1/ru/admin/getObjects', {
        }, { headers } )
        .then(response =>  {
            this.objects = response.data.data;
            commit("adminPages", response);
        })
        .catch(error => console.log(error))
    },
  },
  
})
