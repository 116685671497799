var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background":"#467599"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',[_vm._v("Logo")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"#467599 logout",attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.language[_vm.selectLang])+" ")])]}}])},[_c('v-list',_vm._l((_vm.language),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.changeLanguage(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-btn',{staticClass:"logout",attrs:{"text":"","dark":""},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('logout'))+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([(_vm.isHidden == '1')?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"logout",attrs:{"text":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('addObject'))+" ")])]}}:null],null,true),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('addNewObj')))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('objectName'),"required":"","rules":[function (v) { return !!v || 'Item is required'; }]},model:{value:(_vm.formData.object_name),callback:function ($$v) {_vm.$set(_vm.formData, "object_name", $$v)},expression:"formData.object_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('objectDesc'),"required":"","rules":[function (v) { return !!v || 'Item is required'; }]},model:{value:(_vm.formData.object_description),callback:function ($$v) {_vm.$set(_vm.formData, "object_description", $$v)},expression:"formData.object_description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),(_vm.valid)?[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-1","text":"","disabled":!_vm.valid},on:{"click":function($event){return _vm.addObject()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])]}}],null,false,481795233),model:{value:(_vm.dialog6),callback:function ($$v) {_vm.dialog6=$$v},expression:"dialog6"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"}),_c('v-card-text',[_vm._v("You successfully added new object")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.reloadWindow(), _vm.dialog6 = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)]:_vm._e()],2)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }