<template>
  <v-container>
    <div class="loaders" v-if="clicked"></div>
    <button @click="change('loader', 'loaders')">Change</button>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      loaders: false
    }),
    methods:{
      change(){
        
      }
    }
  }
</script>

<style scoped>
.loaders{
  width: 70px;
  height: 70px;
  background-color: red;
  position: absolute;
  top: 50% ;
  left: 50%;
  /* transform: translateX(-50%) translateY(-50%); */
  animation: spin 1.2s ease-in-out;
}

@keyframes spin{
  0%{
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  100%{
    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
  }
  
}
</style>
