<template>
    <v-app>
        <Header />
        <v-container> 
            <h2>{{ $t('listOfCamera') }}</h2>
            <br>
            
            <template>
                <div >
                    <template>
                            <div v-for="item in camera" :key="item.id">
                                        <v-list-item>
                                            <v-list-item-content >
                                                <h3>{{ $t('cameraName') }}: <span id="ipLink">{{ item.camera_name }}</span></h3>
                                                <v-list-item-title>{{ $t('objectName') }}: {{ item.object_name }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                        <v-btn
                                                            :href="item.camera_link"
                                                            target="_blank"
                                                            class="mx-2"
                                                            fab
                                                            dark
                                                            small
                                                            color="#D64045"
                                                        >
                                                            <v-icon dark>
                                                                mdi-cctv
                                                            </v-icon>
                                                        </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider></v-divider>                               
                            </div>
                        </template> 
                    
                </div>
            </template>    
            </v-container> 
        <Footer />
    </v-app>
</template>

<script>
import Header from '../components/header'
import Footer from '../components/footer'
import axios from 'axios'
export default {
    components:{
        Header,
        Footer
    }, 
    data(){
        return{
            dialog: false, 
            camera: {}
        }
    },
    mounted(){
        var self = this;
        const headers = { 'X-Auth': localStorage.getItem("token") }
        axios.get('https://watch.mediana.kz/api/v1/ru/objectUser/getObjectCamera', { headers })
        .then(res => { 
            console.log(res)
            self.camera = res.data.data 
        })
        .catch(error => console.log(error))
    },
}
</script>

<style scoped>
.container{
    margin-top: 30px;
}
h4,h5{
    font-weight: normal;
}
h4{
    color: #1D3354;
}
h5{
    color: #467599;
}
.text{
    text-decoration: none;
    color: black;
}
.v-list-item__title, a{
      color: #467599;
}
h3{
    font-weight: normal;
    line-height: 45px;
    color: #1D3354;
   
}
</style>