<template>
    <v-app>
        <Header />
        <router-link to="/admin/list/objects">
            <v-btn
                color="#467599"
                dark
                text
                class="my-auto"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            </router-link>
        <v-container>
            
            <div v-for="obj in objects" :key="obj.id">
                
            </div>
            <v-row
                align="center"
                justify="space-around"
            >
                <v-btn 
                    text
                    @click="showList(), classes()"
                    color="#467599"
                    :class="{active: isActive}"
                >
                {{ $t('listOfCamera') }}
                    
                    
                </v-btn>
                <v-btn
                    text
                    color="#467599"
                    @click="showAdmin(),  classes1()"
                    :class="{active1: isActive1}"
                >
                    {{ $t('addUser') }}
                </v-btn>
                <v-btn
                    text
                    color="#467599"
                    @click="showCamera(),  classes2()"
                    :class="{active1: isActive2}"
                >
                    {{ $t('addCamera') }}
                </v-btn>
            </v-row>
            <br>
            <hr>
            <div v-if="isList == '1'" :active-class="isActive">
                    <br> 
                    <div v-for="item in objects" :key="item.id">
                    <div v-if="item.id == id">
                    <h2>{{ $t('object') }} - {{ item.object_name }} </h2>
                    <h1>{{ $t('admin') }} - {{ objectName.name }} {{ objectName.surname }}</h1>
                    </div>
                    </div>
                    <br>
                <div >
                    
                    <v-row>
                        <v-col cols="12" sm="6">
                            <template>
                              <v-card
                                        class="ml-auto mr-auto"
                                        width="100%"
                                        max-width = "800px"
                                        tile     
                                    >
                                    <div  v-for="item in listofCamera" :key="item.id">
                                        <v-list-item>
                                            <v-list-item-content >
                                                <h3>{{ $t('cameraName') }}: <span id="ipLink">{{ item.camera_name }}</span></h3>
                                                <v-list-item-title>{{ $t('ipLink') }}: {{ item.ip_link }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-row>
                                                    <v-col>
                                                        <v-btn
                                                            class="mx-2"
                                                            fab
                                                            dark
                                                            small
                                                            color="#D64045"
                                                            @click="editIt(item.id, item.ip_link, item.c_user, item.c_password, item.s_user, item.s_password, item.c_port, item.s_port)"
                                                        >
                                                            <v-icon dark>
                                                                mdi-pencil-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn
                                                            :href="item.camera_link"
                                                            target="_blank"
                                                            class="mx-2"
                                                            fab
                                                            dark
                                                            small
                                                            color="#D64045"
                                                        >
                                                            <v-icon dark>
                                                                mdi-cctv
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    
                                                </v-row>
                                                
                                            </v-list-item-action>
                                        </v-list-item>

                                        <v-divider></v-divider>
                                    </div>
                                    
                                    </v-card>  
                        </template> 
                        </v-col>
                        <v-col cols="12" sm="6">
                            
                            
                          
                            <div v-if="isEdit">
                                <v-form
                                    
                                >
                                    <v-text-field
                                        :label="$t('ipLink')"
                                        v-model="editedData.ip_link"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('cUser')"
                                        v-model="editedData.c_user"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('cPassword')"
                                        v-model="editedData.c_password"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('serverUser')"
                                        v-model="editedData.s_user"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('serverPassword')"
                                        v-model="editedData.s_password"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('cPort')"
                                        v-model="editedData.c_port"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('serverPort')"
                                        v-model="editedData.s_port"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                    <v-btn
                                        color="#D64045"
                                        class="mr-4"
                                        dark
                                        @click="edit(),  dialog2 = true"
                                    >
                                        {{ $t('save') }}
                                        </v-btn>   
                                        <v-btn
                                        class="mr-4"
                                        @click="closeEdit(),  dialog2 = true"
                                    >
                                        {{ $t('close') }}
                                    </v-btn>                                
                                </v-form>

                                    <v-dialog
                                    v-model="dialog2"
                                    max-width="400"
                                    >
                                    <v-card>
                                        <v-card-title class="text-h5">
                                        {{  $t('editCamera')}}
                                        </v-card-title>

                                        <v-card-text>
                                            {{ $t('reloadPage') }}
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>

                                        <v-btn
                                            color="#D64045"
                                            text
                                            @click="gotoList()"
                                        >
                                            OK
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                                <!--  -->
                            </div>
                        </v-col>
                    </v-row>  
                    </div> 
            </div>
            <div v-if="isList == '2'" class="shown">
                <br>
                        <v-form
                            ref="form"
                            lazy-validation
                            v-model="valid"
                        >
                            <v-text-field
                                v-model="formData.name"
                                :label="$t('name')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.surname"
                                :label="$t('surname')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.lastname"
                                :label="$t('lastname')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.login"
                                :label="$t('email')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="formData.password"
                                :label="$t('password')"
                                required
                                :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                            <template v-if="valid">
                                <v-row justify="center">
                                    <v-dialog
                                    v-model="dialog4"
                                    persistent
                                    max-width="290"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="mr-4"
                                            type="submit"
                                            @click.prevent="submit()"
                                            color="#D64045"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!valid"
                                        >
                                            {{ $t('save') }}
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title class="text-h5">
                                        
                                        </v-card-title>
                                        <v-card-text>{{ $t('success') }}</v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="green darken-1"
                                            text
                                            @click="dialog4 = false, reloadedWindow()"
                                        >
                                            OK
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                                </v-row>
                                </template>
                        </v-form>
            </div>
            <div v-if="isList == '3'" class="shown">
                <br>
                            <v-form
                                ref="form2"
                                lazy-validation
                                v-model="valid1"
                            >
                                <v-text-field
                                v-model="cameraData.camera_name"
                                :label="$t('cameraName')"
                                required
                                 :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>

                            <v-text-field
                                v-model="cameraData.c_user"
                                :label="$t('cUser')"
                                required
                                 :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="cameraData.c_password"
                                :label="$t('cPassword')"
                                required
                                 :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                        
                            <v-text-field
                                v-model="cameraData.s_user"
                                :label="$t('serverUser')"
                                required
                                 :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                            <v-text-field
                                v-model="cameraData.s_password"
                                :label="$t('serverPassword')"
                                required
                                 :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>
                            <v-text-field
                                v-model="cameraData.ip_link"
                                :label="$t('linkOfTheCamera')"
                                required
                                 :rules="[v => !!v || 'Item is required']"
                            ></v-text-field>

                            <v-btn
                                class="mr-4"
                                @click="addCamera()"
                                color="#D64045"
                                dark
                                :disabled="!valid1"
                            >
                                {{ $t('save') }}
                            </v-btn>
                            </v-form>
                        <v-dialog
                            v-model="dialog1"
                            max-width="400"
                            >
                            <v-card>
                                <v-card-title class="text-h5">
                                {{ $t('newCameraAdded') }}
                                </v-card-title>

                                <v-card-text>
                                {{ $t('gotoCamera') }}
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                    color="#D64045"
                                    text
                                    @click.stop="gotoList()"
                                >
                                    OK
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                            <v-dialog
                            v-model="dialog3"
                            max-width="400"
                            >
                            <v-card>
                                <v-card-title class="text-h5">
                                {{ $t('newCameraAdded') }}
                                </v-card-title>

                                <v-card-text>
                                {{ $t('gotoCamera') }}
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn
                                    color="#D64045"
                                    text
                                    @click.stop="dialog3 = false"
                                >
                                    OK
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
            </div>
        </v-container>
        <Footer />
    </v-app>
</template>

<script>
import axios from 'axios'
import Header from './header'
import Footer from './footer'
export default {
    components:{
        Header,
        Footer
    },
    data(){
        return{
            objects: this.$store.state.objects,
            width: '',
            isSelect: false,
            dialog4: false,
            dialog3: false,
            dialog2: false,
            dialog1: false,
            isEdit: false,
            objects: [],
            isList: '1',
            isHidden: true,
            id: this.$route.params.id,
            isHidden: true,
            listofCamera: {},
            camera: {},
            dialog: false,
            select: null,
            // access_level: [
                
            // ],
            items: [ 1920, 1440, 1040, 400],
            formData:{
                name: '',
                surname: '',
                lastname: '',
                login: '',
                password: '',
                object_id: this.$route.params.id,
            },
            cameraData:{
                camera_name: '',
                object_id: this.$route.params.id,
                c_user: '',
                c_password: '',
                s_user: '',
                s_password: '',
                ip_link: ''
            },
            editedData: {
                id: '',
                ip_link: '',
                c_user: '',
                c_password: '',
                s_user: '',
                s_password: '',
                c_port: '',
                s_port: ''
            },
            isActive: true,
            isActive1: false,
            isActive2: false,
            objectId: this.$route.params.id,
            objectName: {},
            valid: true,
            valid1: true,

        }
    },
    methods:{
        show(){
            this.isHidden = false;
        },
        submit(){
            this.$refs.form.validate()
            var self = this;
            const headers = { "X-Auth": localStorage.getItem("token") };  
            axios.post('https://watch.mediana.kz/api/v1/ru/admin/addPersonObject', this.formData, { headers })
                .then(response => console.log(response))
                .catch(error => console.log(error))
        },
        showList(){
            this.isList = '1';
        },
        showAdmin(){
            this.isList = '2';
        },
        showCamera(){
            this.isList = '3';
        },
        addCamera(){
            this.$refs.form2.validate()
           var self = this;
           const headers = { "X-Auth": localStorage.getItem("token") };
           axios.post('https://watch.mediana.kz/api/v1/ru/admin/addCamera', self.cameraData, { headers })
           .then(response => { console.log(response) 
            if(response.status == 200){
                this.dialog1 = true;
            }
           })
           .catch(error => { 
               console.log(error) 
            })
        },
        gotoList(){
            this.isList = '1',
            window.location.reload();
        },
        edit(){
            var self = this;
           const headers = { "X-Auth": localStorage.getItem("token") };
           axios.post('https://watch.mediana.kz/api/v1/ru/admin/editObjectCamera', self.editedData, { headers })
           .then(response => console.log(response))
           .catch(error => console.log(error))
           
        },
        editIt($eventId, $eventIP, $eventCU, $eventCP, $eventSU, $eventSP, $eventCPort, $eventSPort){
            this.isEdit = true;
            this.isSelect = false;
            this.editedData.id = $eventId;
            this.editedData.ip_link = $eventIP;
            this.editedData.c_user = $eventCU;
            this.editedData.c_password = $eventCP;
            this.editedData.s_user = $eventSU;
            this.editedData.s_password = $eventSP;
            this.editedData.c_port = $eventCPort;
            this.editedData.s_port = $eventSPort;
        },
        closeEdit(){
            this.isEdit = false;
        },
        classes(){
            this.isActive = true
            this.isActive1 = false
            this.isActive2 = false
        },
        classes1(){
            this.isActive1 = true
            this.isActive = false
            this.isActive2 = false
        },
        classes2(){
            this.isActive2 = true
            this.isActive = false
            this.isActive1 = false
        },
        closeFrame(){
            this.dialog = false
        },
        selected(){
            this.isSelect = true
            this.isEdit = false
        },
        reloadWindow(){
            window.location.reload();
        }
    },
    mounted(){
        
        var self = this;
        const headers = { "X-Auth": localStorage.getItem("token") };
        axios.post('https://watch.mediana.kz/api/v1/ru/admin/getObjectCamera', { objectId: self.objectId }, { headers })
        .then(res => self.listofCamera = res.data.data)
        .catch(error => console.log(error))      
        axios.post('https://watch.mediana.kz/api/v1/ru/admin/getObjects', {
        }, { headers } )
        .then(response =>  {
            this.objects = response.data.data;
        })
        .catch(error => console.log(error))

        axios.get(`https://watch.mediana.kz/api/v1/ru/admin/getObjectAdmin?objectId=${self.objectId}`, { headers } )
        .then(response =>  {
            this.objectName = response.data.data;
        })
        .catch(error => console.log(error))
    },
}
</script>

<style scoped>
.container{
    margin-top: 0px;
}
.videoShow{
    background: #FAFAFA; 
    position: absolute;
    width: 100%;
    top: 100px;
    z-index:99;
    border-radius: 10px;
}
.close{
    width: 100%;
}
.headline{
    border-radius: 10px 10px 0px 0px;
}
.list{
    filter: blur(8px);
  -webkit-filter: blur(8px);
}
.shown{
    text-align: center;
}

form{
    background: white;
    border-radius: 5px;
    padding: 20px;
    z-index: 4;
    box-shadow: 0px 0px 5px #dad5d5;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    z-index: 10;
}
h2{
    color: #D64045;
}
h1{
    color: #1D3354;
    font-size: 20px;
    font-weight: 600;
}
h3{
    font-weight: normal;
    line-height: 45px;
    color: #1D3354;
   
}
.addAdmin{
    font-weight: normal;
    font-size: 22px;
    font-weight: 600;
}
.v-list-item__content{
    text-align: left;
}
.addBtn{
    width: 30px;
    height: 30px;
}
#ipLink{
    text-decoration: underline;
}
.v-list-item__title, a{
      color: #467599;
}
.v-list-item__title{
  line-height: 20px !important;
}
.v-list{
    margin-bottom: 10px;
}
a{
    font-size: 15px;
}
.row{
    margin-top: 10px;
}
.active{
    background: #467599;
    color: white !important;
}
.active1{
    background: #467599;
    color: white !important;
}
.active2{
    background: #467599;
    color: white !important;
}
</style>