import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      logout: 'Log out',
      addObject: 'Add object',
      close: 'Close', 
      save: 'Save',
      setUser: 'Set user to this object',
      address: 'Address',
      admin: 'Admin',
      listOfCamera: 'List of Camera',
      addUser: 'Add admin to Object',
      addCamera: 'Add Camera',
      ipLink: 'IP_Link',
      idOfCamera: 'ID of camera',
      linkOfTheCamera: 'Link of the camera',
      addNewAdminUser: 'Add new Admin user',
      addNewCamera: 'Add new Camera',
      name: "Name",
      surname: "Surname",
      lastname: "Lastname",
      email: "E-mail",
      password: "Password",
      select: "Select...",
      success: "You successfully added new user",

      listOfUser: "List of User",
      deleteUser: "Delete User",
      userID: "User ID",
      listOfUOfthisO: 'List of users of this object',
      fullname: 'Fullname',
      accessLevel: "Access Level",
      camera: "Camera",
      login: 'Login',
      welcome: "Welcome",
      listOfObjects: 'List of objects',
      serverPassword: 'Server password',
      serverUser: 'Server User',
      cUser: "Camera User",
      cPassword: "Camera Password",
      addNewObj: "Add new Object",
      objectName: "Object Name",
      objectDesc: "Object Description",
      addNewUser: "Add new User",
      newCameraAdded: "You added a new camera",
      gotoCamera: "Go to list of camera",
      editCamera: "You edited the camera",
      reloadPage: "To save changes click OK",
      cameraName: "Camera Name",
      object: "Object",
      back: 'Back',
      chooseWidth: "Please choose the width of the camera",
      cPort: "Camera Port",
      serverPort: "Server Port"
    },
    ru:{
      logout: 'Выйти',
      addObject: 'Добавить обьект',
      close: 'Закрыть', 
      save: 'Сохранить',
      setUser: 'Назначить пользователя на обьект',
      address: 'Адрес',
      admin: 'Админ',
      listOfCamera: 'Список камер',
      addUser: 'Добавить админ на обьект',
      addCamera: 'Добавить камеру',
      ipLink: 'IP_Link',
      idOfCamera: 'ID камеры',
      linkOfTheCamera: 'Ссылка на камеру',
      addNewAdminUser: 'Добавить нового пользователя',
      addNewCamera: 'Добавить новую камеру',
      name: "Имя",
      surname: "Фамилия",
      lastname: "Отчество",
      email: "E-mail",
      password: "Пароль",
      select: "Выбрать...",
      listOfUser: "Список пользователей",
      deleteUser: "Удалить пользователя",
      userID: "ID Пользователя",
      listOfUOfthisO: 'Список пользователей данного обьекта',
      fullname: 'Полное имя',
      accessLevel: "Уровень доступа",
      camera: "Камера",
      welcome: "Добро пожаловать",
      login: 'Войти',
      listOfObjects: 'Список обьектов',
      serverPassword: 'Пароль сервера',
      serverUser: 'Пользователь сервера',
      cUser: "Пользователь камеры",
      cPassword: "Пароль камеры",
      addNewObj: "Добавить новый обьект",
      objectName: "Имя обьекта",
      objectDesc: "Описание обьекта",
      addNewUser: "Добавить нового пользователя",
      newCameraAdded: "Вы добавили новую камеру",
      gotoCamera: "Перейти к списку камер?",
      editCamera: "Вы ввели изменения в камеру",
      reloadPage: "Чтобы сохранить изменения нажмите ОК",
      cameraName: "Название Камеры",
      object: "Обьект",
      back: 'Назад',
      chooseWidth: "Пожалуйста выберите ширину окна камеры",
      cPort: "Камера порт",
      serverPort: "Сервер порт",
      success: "Вы успешно добавили нового пользователя",

    },
    kk: {
      logout: 'Шығу',
      addObject: 'Обьект қосу',
      close: 'Жабу', 
      save: 'Сақтау',
      setUser: 'Пайдаланушыны обьектіге тағайындау',
      address: 'Мекен-жайы',
      admin: 'Админ',
      listOfCamera: 'Камералар тізімі',
      addUser: 'Обьектіге админді қосу',
      addCamera: 'Камера қосу',
      ipLink: 'IP_Link',
      idOfCamera: 'Камера ID',
      linkOfTheCamera: 'Камераға сілтеме',
      addNewAdminUser: 'Жаңа админ қолданушыны қосу',
      addNewCamera: 'Жаңа камера қосу',
      name: "Аты",
      surname: "Тегі",
      lastname: "Әкесінің аты",
      email: "E-mail",
      password: "Құпия сөз",
      select: "Таңдау...",
      listOfUser: "Қолданушылар тізімі",
      deleteUser: "Қолданушыны кетіру",
      userID: "Қолданушы ID",
      listOfUOfthisO: 'Обьектінің қолданушылар тізімі',
      fullname: 'Толық аты-жөні',
      accessLevel: "Рұқсат деңгейі",
      camera: "Камера",
      login: 'Кіру',
      welcome: "Қош келдіңіз",
      listOfObjects: 'Обьектілер тізімі',
      serverPassword: 'Сервердің құпия сөзі',
      serverUser: 'Сервердің қолданушысы',
      cUser: "Камера Қолданушысы",
      cPassword: "Камера Құпия сөз",
      addNewObj: "Жаңа обьекті қосу",
      objectName: "Обьектің аты",
      objectDesc: "Обьектің сипаты",
      addNewUser: "Жаңа қолданушыны қосу",
      newCameraAdded: "Сіз жаңа камера қостыңыз",
      gotoCamera: "Камера тізіміне өтіңіз",
      editCamera: "Сіз камера сипатына өзгертпелер еңгіздіңіз",
      reloadPage: "Өзгертпелерді сақтау үшін ОК басыңыз",
      cameraName: "Камера аты",
      object: "Обьект",
      back: 'Артқа',
      chooseWidth: "Керекті камера енін таңдаңыз",
      cPort: "Камера порты",
      serverPort: "Сервер порты",
      success: "Сіз жаңа қолданушыны қостыңыз",


    }

  }
  // locales.keys().forEach(key => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1]
  //     messages[locale] = locales(key)
  //   }
  // })
  
})

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })
