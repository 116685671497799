<template>
  <v-app>
    <v-snackbar
      v-model="show"
      :vertical="true"
    >
      Email or password is incorrect

      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
        <v-col  cols="12" md="6" class="hidden-sm-and-down">
        <h1>Welcome</h1>
        <v-img
            src="../assets/back.jpeg"
            height="100%"
        ></v-img>
        </v-col>
        <v-col cols="12" md="6">     
            <v-layout align-center justify-center>  
                <v-form
                    lazy-validation
                >
                    <h3>{{ $t('login') }}</h3>
                    <br>
                    <v-text-field
                        v-model="formData.login"
                        label="Login"
                        required
                        persistent-hint
                        outlined
                        color="#1D3354"
                    ></v-text-field>
                    
                    <v-text-field
                        v-model="formData.password"
                        :label="$t('password')"
                        required
                        type="password"
                        persistent-hint
                        outlined
                        color="#1D3354"
                    ></v-text-field>

                    <v-btn 
                        dark color="#D64045"
                        @click="login"
                    >
                        {{ $t('login') }}
                    </v-btn> 
                </v-form>
            </v-layout>  
        </v-col>  
    </v-row>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
      show: false,
      formData:{
          login: '',
          password: ''
      }
  }),
  methods:{
      login(){
          this.$store.dispatch('login', this.formData).then(() => {
            if(localStorage.getItem("status") == "200"){
              if(localStorage.getItem("access") == "admin"){
                  this.$router.push('/admin/list/objects');
              }else if(localStorage.getItem("access") == "object_admin"){
                this.$router.push('/object/admin');
              }
              else if(localStorage.getItem("access") == "object_user"){
                this.$router.push('/object/user');
              }
            }else if(localStorage.getItem("status") == "undefined"){
              console.log("Email or password is incorrect")
              this.show = true;
            }
          })
      }
  }
};
</script>
<style scoped>
.v-form{
    width: 500px;
    padding: 30px 50px;
    border-radius: 10px;
    /* box-shadow: 1px 1px 10px lightgrey; */
    position: absolute;
    top: 30%;
    background: white;
}
@media screen and (max-width: 959px){
  .v-form{
    box-shadow: 1px 1px 10px lightgrey;
    background: #fdfdfd;
  }
}
@media screen and (max-width: 565px){
  .v-form{
    width: 300px;
    padding: 0%;
    box-shadow:  0px 0px 0px white !important;
    background: white !important;
  }
}
.v-btn:not(.v-btn--round).v-size--default{
    height: 46px;
}

h3{
    color: #1D3354;
    font-size: 30px;
}

h1{
    position: absolute;
    z-index: 1;
    color: #9ED8DB;
    top: 40%;
    font-size: 56px;
    left: 17%;
}

@media screen and (max-width: 1400px){
  h1{
    left: 14%;
  }
}
</style>