import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login'
import adminPage from '../components/adminPage'
import adminObjectCamera from '../components/adminObjectCamera'
import objectPage from '../components/objectAdminPage'
import objectUserPage from  '../components/objectUserPage.vue'
// import adminObjectCameraId from  '../components/adminObjectCameraId.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/admin/list/objects',
    name: 'AdminListOfObjects',
    component: adminPage,
    beforeEnter: (to, from, next) =>{
      if (store.state.token){
        next()
      }else{
        router.push('/').catch(()=>{});
      }
    }
  },
  {
    path: '/admin/list/objects/:id',
    name: 'adminObjectCamera',
    component: adminObjectCamera,
    beforeEnter: (to, from, next) =>{
      if (store.state.token){
        next()
      }else{
        router.push('/').catch(()=>{});
      }
    },
  },
  // {
  //   path: '/admin/list/objects/:id/:id',
  //   name: 'adminObjectCameraIdLink',
  //   component: adminObjectCameraId,
  //   beforeEnter: (to, from, next) =>{
  //     if (store.state.token){
  //       next()
  //     }else{
  //       router.push('/').catch(()=>{});
  //     }
  //   },
  // },
  {
    path: '/object/admin',
    name: 'ObjectList',
    component: objectPage,
    beforeEnter: (to, from, next) =>{
      if (store.state.token){
        next()
      }else{
        router.push('/').catch(()=>{});
      }
    }
  },
  {
    path: '/object/user',
    name: 'ObjectUser',
    component: objectUserPage,
    beforeEnter: (to, from, next) =>{
      if (store.state.token){
        next()
      }else{
        router.push('/').catch(()=>{});
      }
    }
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
