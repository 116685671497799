<template>
    <div 
        style="background: #467599"
    >
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <p>Logo</p>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-menu offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            dark text
                            class="#467599 logout"
                            v-bind="attrs"
                            v-on="on"
                            >
                            {{ language[selectLang] }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            link
                            v-for="(item, index) in language"
                            :key="index"
                            @click="changeLanguage(index)"
                            >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn text dark 
                        class="logout"
                        @click="logout"
                    >
                        {{ $t('logout') }}
                    </v-btn>
                    
                    <v-dialog
                    v-model="dialog1"
                    persistent
                    max-width="600px"
                    >
                    <template v-slot:activator="{ on, attrs }" v-if="isHidden == '1'">
                        <v-btn
                        text dark 
                        v-bind="attrs"
                        v-on="on"
                        class="logout"
                        >
                        {{ $t('addObject') }}
                        </v-btn>
                    </template>
                    <v-form
                                    ref="form"
                                    lazy-validation
                                    v-model="valid"
                                >
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ $t('addNewObj') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                        v-model="formData.object_name"
                                        :label="$t('objectName')"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                        v-model="formData.object_description"
                                        :label="$t('objectDesc')"
                                        required
                                        :rules="[v => !!v || 'Item is required']"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog1 = false"
                        >
                            {{ $t('close') }}
                        </v-btn>
                        <template  v-if="valid">
                            <v-row justify="center">
                                <v-dialog
                                v-model="dialog6"
                                persistent
                                max-width="290"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="addObject()"
                                        :disabled="!valid"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{ $t('save') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="text-h5">
                                    
                                    </v-card-title>
                                    <v-card-text>You successfully added new object</v-card-text>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="green darken-1"
                                        text
                                        @click="reloadWindow(), dialog6 = false"
                                    >
                                        OK
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </v-row>
                            </template>
                        </v-card-actions>
                    </v-card>
                     </v-form>
                    </v-dialog>

                    <!-- <v-dialog
                    v-model="dialog3"
                    persistent
                    max-width="600px"
                    >
                    <template v-slot:activator="{ on, attrs }" v-if="isHidden == '2'">
                        <v-btn
                        text dark 
                        v-bind="attrs"
                        v-on="on"
                        class="logout"
                        >
                        {{ $t('setUser') }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{  $t('addNewUser') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select                          
                                            :label="$t('select')"
                                            required
                                            :items="camerasList"
                                            item-text="ip_link"
                                            item-value="id"
                                            v-model="cameraId"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select                          
                                            :label="$t('select')"
                                            required
                                            :items="usersList"
                                            item-text="login"
                                            item-value="id"
                                            v-model="userId"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog3 = false"
                        >
                            {{ $t('close') }}
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog3 = false, setUserObject()"
                        >
                            {{ $t('save') }}
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog> -->
                </v-col>
            </v-row>
           
        </v-container>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            valid: true,
            language: {
                'kk': 'KZ',
                'ru': 'RU',
                'en': 'EN'
            },
            // usersList: [],
            // camerasList: [],
            cameraId: '',
            userId: '',
            isHidden: '',
            dialog6: false,
            dialog5: false,
            dialog4: false,
            dialog3: false,
            dialog1: false,
            dialog: false,
            formData:{
                object_name: '',
                object_description: ''
            },
            
            adminObjectData:{
                name: '',
                surname: '',
                lastname: '',
                email: '',
                password: '',
                access_level_id: ''
            },
            adminUserData:{
                cameraId: '',
                userId: '',
                objectId: '',
            },
            
        }
    },
    methods:{
        reloadWindow(){
            window.location.reload();
        },
        logout(){
            this.$store.dispatch("logout").then(() => {
                this.$router.push('/')
            })
        },
        addObject(){
            this.$refs.form.validate()
            var self = this;
            const headers = { "X-Auth": localStorage.getItem("token") };
            axios.post('https://watch.mediana.kz/api/v1/ru/admin/addObject', self.formData, { headers })
            .then(response => console.log(response))
            .catch(error => console.log(error))
        },
        addCamera(){
           var self = this;
           const headers = { "X-Auth": localStorage.getItem("token") };
           axios.post('https://watch.mediana.kz/api/v1/ru/admin/addCamera', self.cameraData, { headers })
           .then(response => console.log(response))
           .catch(error => console.log(error))
        },
        addAdminObject(){
            var self = this;
           const headers = { "X-Auth": localStorage.getItem("token") };
           axios.post('https://watch.mediana.kz/api/v1/ru/admin/addAdminObject', self.adminObjectData, { headers })
           .then(response => console.log(response))
           .catch(error => console.log(error));
        },
        makeAdmin(){
           var self = this;
           const headers = { "X-Auth": localStorage.getItem("token") };
           axios.post('https://watch.mediana.kz/api/v1/ru/admin/appointObjectAdmin', self.adminUserData, { headers })
           .then(response => console.log(response))
           .catch(error => console.log(error));
        },
        // setUserObject(){
        //     var self = this;
        //    const headers = { "X-Auth": localStorage.getItem("token") };
        //    axios.post('http://watch.mediana.kz/v1/ru/objectAdmin/setUserObject', { 
        //        cameraId: self.cameraId,
        //        userId: self.userId,
        //    }, { headers })
        //    .then(response => console.log(response))
        //    .catch(error => console.log(error));
        // },
        changeLanguage($event){
           this.$store.commit('setLang', $event);
           location.reload();
        }
        
    },
    created(){
        if(localStorage.getItem("access") == 'admin'){
            this.isHidden = '1';
        }else if(localStorage.getItem("access") == 'object_admin'){
            this.isHidden = '2';
        }else if(localStorage.getItem("access") == 'object_user'){
            this.isHidden = '3';
        }
    },
    mounted(){
        // var self = this;
        // const headers = { "X-Auth": localStorage.getItem("token") };
        // axios.post('http://watch.mediana.kz/v1/ru/objectAdmin/getObjectCamera', {}, { headers })
        // .then(response => self.camerasList = response.data.data)
        // .catch(error => console.log(error))
        // axios.post('http://watch.mediana.kz/v1/ru/objectAdmin/getUserObject', {}, { headers })
        // .then(response => self.usersList = response.data.data)
        // .catch(error => console.log(error))
    },
    computed:{
        selectLang(){
            return this.$store.getters.getLanguage;
        }
    }
}
</script>

<style scoped>
.logout{
    float: right;
    color: #E9FFF9;
}
</style>