<template>
    <v-app>
        <Header />
        <v-container>
            <h2>{{ $t('listOfObjects') }}</h2>
            <br>
            <template>
                <div v-for="obj in objects" :key="obj.id" >
                    <v-card
                        max-width="600"
                        class="ml-0"   
                         
                    >
                        <v-list>
                            <v-list-item
                            >
                                <v-list-item-content>
                                <h3>{{ obj.object_name }}</h3>
                                <v-list-item-subtitle>{{ $t('address') }}: {{ obj.object_description }}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <router-link class="text" :to="{path: `/admin/list/objects/${obj.id}`}"> <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="#D64045"
                                    >
                                        <v-icon dark>
                                            mdi-information
                                        </v-icon>
                                    </v-btn> 
                                </router-link>
                                </v-list-item-action>
                                
                            </v-list-item>
                        </v-list>
                    </v-card>
                </div>
            </template>
        </v-container>
        <Footer />
    </v-app>
</template>

<script>
import axios from 'axios'
import Header from './header'
import Footer from './footer'
export default {
    components:{
        Header,
        Footer
    },
    data(){
        return{
            objects: ''
        }
    },
    methods:{
        
    },
    mounted(){
        var self = this; 
        const headers = { "X-Auth": localStorage.getItem("token") };
        axios.post('https://watch.mediana.kz/api/v1/ru/admin/getObjects', {
        }, { headers } )
        .then(response =>  {
            this.objects = response.data.data;
            commit("adminPages", response);
        })
        .catch(error => console.log(error))
    }
}
</script>

<style scoped>
.text{
    text-decoration: none;
    color: black;
}
.container{
    margin-top: 30px;
}
h2{
    color: #D64045;
}
.v-list-item__title{
  color: #467599;
  line-height: 20px !important;
}

h3{
    font-weight: normal;
    line-height: 45px;
    color: #1D3354;
}
.v-list{
    margin-bottom: 10px;
}

</style>